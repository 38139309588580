import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'core/ui/decorators';

import {
  actions as HypothesisLinkingActions,
  getters as HypothesisLinkingGetters,
  fns as HypothesisLinkingFns,
} from 'bundles/p13n/modules/hypothesis_linking';

import HypothesisLinkSearchPicker from 'bundles/p13n/components/hypothesis_link_search_picker';
import HypothesisForm from 'bundles/p13n/components/hypothesis_form';

@connect({
  shouldCreateHypothesis: HypothesisLinkingGetters.shouldCreateHypothesis,
})
class HypothesisLink extends Component {
  state = {
    isExpCollabEnabled: false,
    isLoading: false,
  };

  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    shouldCreateHypothesis: PropTypes.bool,
  };

  static defaultProps = {
    shouldCreateHypothesis: false,
  };

  componentDidMount() {
    const { currentProjectId } = this.props;
    this.setState({ isLoading: true });

    HypothesisLinkingFns.isExpCollabIntegrationEnabled(currentProjectId)
      .then(isExpCollabEnabled => {
        this.setState({ isExpCollabEnabled, isLoading: false });
      })
      .catch(() => {
        this.setState({ isExpCollabEnabled: false, isLoading: false });
      });
  }

  componentWillUnmount() {
    HypothesisLinkingActions.shouldCreateHypothesis(false);
  }

  render() {
    const { shouldCreateHypothesis, currentProjectId } = this.props;
    const { isExpCollabEnabled, isLoading } = this.state;

    if (isLoading || !isExpCollabEnabled) {
      return null;
    }

    return (
      <div data-testid="hypothesis" className="push-double--bottom">
        <HypothesisLinkSearchPicker projectId={currentProjectId} />
        <div className="push-triple--bottom" />

        {shouldCreateHypothesis && <HypothesisForm />}
      </div>
    );
  }
}

export default HypothesisLink;

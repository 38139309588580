import {
  SearchPicker,
  Dropdown,
  Icon,
  Spinner,
  Tile,
  Input,
} from '@optimizely/axiom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _debounce from 'lodash/debounce';

import config from 'atomic-config';

import { connect } from 'core/ui/decorators';

import {
  actions as HypothesisLinkingActions,
  getters as HypothesisLinkingGetters,
} from 'bundles/p13n/modules/hypothesis_linking';

import ui from 'core/ui';

const iceApiBaseUrl = `${config.get('env.ICE_BASE_URL')}/api`;

const searchHypothesesByProjectId = async (projectId, query) => {
  const searchQuery = query ? `search_query=${encodeURIComponent(query)}` : '';
  const iceUrl = `${iceApiBaseUrl}/experimentation-project/${projectId}/hypotheses?${searchQuery}`;
  try {
    const response = await fetch(iceUrl, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      ui.showNotification({
        message:
          'There was an error fetching hypotheses. However, this should not prevent you from creating the experiment.',
        type: 'error',
      });
      return [];
    }
    return response.json();
  } catch (error) {
    ui.showNotification({
      message:
        'There was an error fetching hypotheses. However, this should not prevent you from creating the experiment.',
      type: 'error',
    });
    return [];
  }
};

const debouncedSearchHypotheses = projectId => {
  const debouncedSearch = _debounce(async ({ query }, callback) => {
    const results = await searchHypothesesByProjectId(projectId, query);
    callback(results);
  }, 500);

  return async ({ query }) => {
    return new Promise(resolve => {
      debouncedSearch({ query }, resolve);
    });
  };
};

@connect({
  hypothesisToLink: HypothesisLinkingGetters.hypothesisToLink,
  linkedHypothesis: HypothesisLinkingGetters.linkedHypothesis,
  shouldCreateHypothesis: HypothesisLinkingGetters.shouldCreateHypothesis,
})
class HypothesisLinkSearchPicker extends Component {
  static propTypes = {
    hypothesisToLink: PropTypes.object,
    linkedHypothesis: PropTypes.object,
    projectId: PropTypes.number.isRequired,
    shouldCreateHypothesis: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    hypothesisToLink: null,
    linkedHypothesis: null,
  };

  componentWillUnmount() {
    HypothesisLinkingActions.saveHypothesisToLink(null);
    HypothesisLinkingActions.saveLinkedHypothesis(null);
  }

  render() {
    const {
      projectId,
      hypothesisToLink,
      linkedHypothesis,
      shouldCreateHypothesis,
    } = this.props;

    const hypothesisLinkData = hypothesisToLink || linkedHypothesis;

    return (
      <div data-testid="hypothesis-link-search-picker">
        {linkedHypothesis ? (
          <h6>Linked Hypothesis</h6>
        ) : (
          <h6>Link Hypothesis</h6>
        )}
        {hypothesisLinkData && (
          <Tile
            name={hypothesisLinkData?.name}
            description={`${hypothesisLinkData?.campaign_name} • HPT-${hypothesisLinkData?.hpt_reference} • ${hypothesisLinkData?.status}`}
            onDismiss={() => {
              HypothesisLinkingActions.saveLinkedHypothesis(null);
              HypothesisLinkingActions.saveHypothesisToLink(null);
            }}
            className="width--300"
          />
        )}
        {shouldCreateHypothesis && (
          <div style={{ width: '223.5px' }}>
            <Input
              value="Create Hypothesis"
              hasClearButton={true}
              isReadOnly={true}
              leftIconName="fa-search"
              onClearButtonClick={() =>
                HypothesisLinkingActions.shouldCreateHypothesis(false)
              }
            />
          </div>
        )}
        {!hypothesisLinkData && !shouldCreateHypothesis && (
          <SearchPicker
            searchFunction={debouncedSearchHypotheses(projectId)}
            supportedTypes={['hypothesis']}>
            {({ availableEntities, isLoading, renderInput, searchQuery }) => (
              <Dropdown width={400} renderActivator={renderInput}>
                <Dropdown.Contents isLoading={isLoading}>
                  {!isLoading &&
                    availableEntities &&
                    availableEntities.map(hypothesis => (
                      <Dropdown.ListItem key={hypothesis.id}>
                        <Dropdown.BlockLink
                          onClick={() => {
                            HypothesisLinkingActions.saveHypothesisToLink(
                              hypothesis,
                            );
                          }}>
                          <Dropdown.BlockLinkText text={hypothesis.name} />
                          <Dropdown.BlockLinkSecondaryText
                            secondaryText={`${hypothesis.campaign_name} • HPT-${hypothesis.hpt_reference} • ${hypothesis.status}`}
                          />
                        </Dropdown.BlockLink>
                      </Dropdown.ListItem>
                    ))}
                  {!isLoading && availableEntities.length === 0 && (
                    <Dropdown.ListItem>
                      <span className="micro muted soft--sides">
                        {searchQuery
                          ? `No hypothesis found containing "${searchQuery}"`
                          : 'No hypothesis found'}
                      </span>
                    </Dropdown.ListItem>
                  )}
                  {isLoading && <Spinner size="small" />}
                  <Dropdown.ListItem
                    role="separator"
                    key="create-hypothesis"
                    ignoreToggle={true}
                    hardSides={true}
                    hardTop={true}>
                    <Dropdown.BlockLink
                      onClick={() => {
                        HypothesisLinkingActions.shouldCreateHypothesis(true);
                      }}>
                      <div className="flex flex-align--center">
                        <Icon name="fa-plus" size="small" />
                        <p className="soft--left flush oui-dropdown__item">
                          Create Hypothesis
                        </p>
                      </div>
                    </Dropdown.BlockLink>
                  </Dropdown.ListItem>
                </Dropdown.Contents>
              </Dropdown>
            )}
          </SearchPicker>
        )}
      </div>
    );
  }
}

export default HypothesisLinkSearchPicker;

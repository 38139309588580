import React from 'react';

import flux from 'core/flux';
import Router from 'core/router';

import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';

import NavConstants from 'optly/services/navigation';

import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import MvtManagerEnums from 'bundles/p13n/sections/manager_mvt/section_module/enums';
import UrlHelper from 'optly/services/url_helper';

import Sidebar from 'bundles/p13n/sections/manager_mvt/components/sidebar';

import { withSidebarAndManagerChangeHistory } from 'bundles/p13n/components/change_history';

import MvtRoutingFns from './routing_fns';

import RoutingFns from '../../routing_fns';

const category = 'Manage Multivariate Test';

/*
 * Routing function that ensures the Campaign Overview dynamic bundle has been fetched
 */
function MultivariateTestManagerSection(ctx, next) {
  import(
    /* webpackChunkName: "mvt-manager-bundle" */
    'bundles/p13n/sections/manager_mvt' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(MultivariateTestManagerSection, module);
    })
    .then(next);
}

const EnsureCodeLintingBundle = (ctx, next) => {
  BundleSplitHelper.getCodeLintingBundleModules().then(next);
};

function setPageTitle({ next, name, params }) {
  next();
  let primaryName = '';
  if (params.experiment_id) {
    const currentExperiment = flux.evaluate(
      LayerExperimentGetters.byId(Number(params.experiment_id)),
    );
    primaryName = !!currentExperiment && currentExperiment.get('name');
  }
  RoutingFns.setPageTitle({
    category: !primaryName ? category : '',
    name,
    primaryName,
  });
}

/*
 * Routes for the campaign overview section in nuclear-router format
 */
const routes = [
  /*
   * Multivariate Test Manager Routes
   */
  {
    // Redirect all visitors to multivariate tests to the sections dashboard.
    match: '/v2/projects/:proj_id/multivariate/:experiment_id',
    handle: [
      ctx =>
        Router.redirect(
          UrlHelper.mvtSectionsDashboard(
            ctx.params.proj_id,
            ctx.params.experiment_id,
          ),
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/sections',
    metadata: { name: 'Sections', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.variationsSections.routingSetup(
          ctx,
          next,
        ),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Variations',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.variationsSections.component />,
        ),
    ],
  },

  {
    match:
      '/v2/projects/:proj_id/multivariate/:experiment_id/sections/:section_id/variations/:variation_id',
    metadata: { name: 'Variation Editor', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        EnsureCodeLintingBundle,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.variationsSections.routingSetup(
          ctx,
          next,
        ),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Variation Editor',
          params,
        }),
      RoutingFns.fetchViewsForCurrentProject,
      ctx => {
        const editorOptions = {
          variationId: Number(ctx.params.variation_id),
          experimentOrSectionId: Number(ctx.params.section_id),
        };

        RoutingFns.renderEditor(
          <MultivariateTestManagerSection.pages.variationsSections.component
            $options={editorOptions}
          />,
          editorOptions.variationId,
          editorOptions.experimentOrSectionId,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/combinations',
    metadata: { name: 'Combinations', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.variationsCombinations.routingSetup(
          ctx,
          next,
        ),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Combinations',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.variationsCombinations.component />,
        ),
    ],
  },

  {
    // NOTE: This is not going to be used for Phase 0.5 and won't work yet.
    match:
      '/v2/projects/:proj_id/multivariate/:experiment_id/combinations/:variation_id',
    metadata: { name: 'Combinations', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.variationsSections.routingSetup(
          ctx,
          next,
        ),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Combinations',
          params,
        }),
      ctx => {
        RoutingFns.renderEditor(
          MultivariateTestManagerSection.pages.variationsSections.component,
          ctx.params.variation_id,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/integrations',
    metadata: { name: 'Integrations', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      RoutingFns.fetchIntegrationsTabData,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.integrations.routingSetup(
          ctx,
          next,
        ),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Integrations',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.integrations.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/metrics',
    metadata: { name: 'Metrics', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.metrics.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Metrics',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.metrics.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/pages',
    metadata: { name: 'Activation', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        EnsureCodeLintingBundle,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.metrics.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Activation',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.pages.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/audiences',
    metadata: { name: 'Audiences', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.audiences.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Audiences',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.audiences.component />,
        ),
    ],
  },

  {
    match:
      '/v2/projects/:proj_id/multivariate/:experiment_id/traffic_allocation',
    metadata: { name: 'Traffic Allocation', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.trafficAllocation.routingSetup(
          ctx,
          next,
        ),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Traffic Allocation',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.trafficAllocation.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/schedule',
    metadata: { name: 'Schedule', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.schedule.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Schedule',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.schedule.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/hypothesis',
    metadata: { name: 'Hypothesis', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.hypothesis.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Hypothesis',
          params,
        }),
      ctx =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.hypothesis.component
            experimentId={Number(ctx.experimentId)}
            experimentType={ctx.experimentType}
          />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/api_names',
    metadata: { name: 'API Names', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.apiNames.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'API Names',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.apiNames.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/custom_code',
    metadata: { name: 'Shared Code', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        EnsureCodeLintingBundle,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.customCode.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Shared Code',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.customCode.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/summary',
    metadata: { name: 'Summary', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      RoutingFns.fetchIntegrationsTabData,
      (ctx, next) =>
        MultivariateTestManagerSection.pages.overview.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Summary',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <MultivariateTestManagerSection.pages.overview.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/multivariate/:experiment_id/history',
    metadata: { name: 'History', category },
    handle: [
      [
        MultivariateTestManagerSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      ({ params }, next) =>
        MvtRoutingFns.fetchLayerExperimentIfValidAndSetLayerIdAsCurrent(
          params.experiment_id,
          next,
        ),
      MvtRoutingFns.fetchRequiredDataForWebMvtManagerChangeHistory,
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'History',
          params,
        }),
      // ctx.fetchedData is set within RoutingFns.fetchRequiredDataForWebManagerChangeHistory
      ({ fetchedData }) => {
        // Define within route since the componentId needs to change for renderMainRegion to remount the component
        const ManagerChangeHistory = withSidebarAndManagerChangeHistory(
          Sidebar,
        );
        RoutingFns.renderMainRegion(
          <ManagerChangeHistory
            fetchedEntities={fetchedData}
            sidebarProps={{
              activeTab: MvtManagerEnums.Tabs.HISTORY,
            }}
          />,
        );
      },
    ],
  },
];

export default routes;

import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Poptip } from 'optimizely-oui';
import { Track } from '@optimizely/segment-js/dist/components';

import Immutable from 'optly/immutable';
import {
  GroupedSidebarNavList,
  Sidebar,
  SidebarNavList,
  SidebarNavListBody,
  SidebarNavListItem,
} from 'react_components/sidebar';

import flux from 'core/flux';
import { connect } from 'core/ui/decorators';
import { fns as HypothesisLinkingFns } from 'bundles/p13n/modules/hypothesis_linking';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { fns as LayerFns } from 'optly/modules/entity/layer';
import * as LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import PermissionsGetters from 'optly/modules/permissions/getters';
import {
  isComponentGroupingEnabled,
  isExperimentOverviewEnabled,
  isHypothesisLinkingEnabled,
} from 'optly/utils/features';

import {
  enums as CampaignManagerEnums,
  fns as CampaignManagerFns,
} from 'bundles/p13n/modules/campaign_manager';
import AdminAccountGetters from 'optly/modules/admin_account/getters';

import keyMirror from 'optly/utils/key_mirror';

import Header from './header';

const metricsPoptip = `You need to add at least one metric to start this campaign.
Metrics will generate campaign results and help you measure performance.`;

/**
 * A parameterizable Sidebar component for the WEB Campaign Managers (AB/MVT/P13N).
 * @param {String} componentId
 * @param {String} campaignType
 * @returns {React.Component<NavSidebarWeb>}
 */

const NavGroupKey = keyMirror({
  Target: null,
  Design: null,
  Track: null,
  Plan: null,
  Settings: null,
});

function groupNavSessionKey(props) {
  const { currentProjectId, currentLayerId } = props;
  const accountId = flux.evaluate(AdminAccountGetters.id);
  return `group_nav__${currentProjectId}_${currentLayerId}_${accountId}`;
}

function getInitialState(props) {
  const sessionKey = groupNavSessionKey(props);
  const groupNav = sessionStorage.getItem(sessionKey);

  if (groupNav) {
    return JSON.parse(groupNav);
  }
  return Object.keys(NavGroupKey).reduce((result, key) => {
    result[key] = {
      isActive: true, // Default to true for expanding all groups
    };
    return result;
  }, {});
}

export default function(componentId, campaignType) {
  @connect({
    currentProject: CurrentProjectGetters.project,
    currentProjectId: CurrentProjectGetters.id,
    currentLayer: CurrentLayerGetters.layer,
    currentLayerId: CurrentLayerGetters.id,

    /* ===========================================================
     * PUBLISH STATUS INDICATORS
     * =========================================================== */
    // Variations Tab
    draftChangesFromAllExperimentsOrSections:
      CurrentLayerGetters.draftChangesFromAllExperimentsOrSections,
    currentCampaignLiveChanges: CurrentLayerGetters.currentCampaignLiveChangesByExperimentStatus(
      LayerExperimentEnums.status.ACTIVE,
    ),
    draftTooltipText: [
      CurrentLayerGetters.newChangesFromAllExperiments,
      CurrentLayerGetters.modifiedChangesFromAllExperiments,
      CurrentLayerGetters.deletedChangesFromAllExperiments,
      (n, m, d) => CampaignManagerFns.draftTooltipText(n.size, m.size, d.size),
    ],

    // Pages Tab
    addedViews: CurrentLayerGetters.addedViews,
    removedViews: CurrentLayerGetters.removedViews,

    // Audiences Tab
    addedAudiences:
      CurrentLayerGetters.addedAudiencesFromAllExperimentsPointingToLayer,
    removedAudiences:
      CurrentLayerGetters.removedAudiencesFromAllExperimentsPointingToLayer,
    hasChangedAudienceConditions:
      CurrentLayerGetters.hasChangedAudienceConditions,

    // Integrations Tab
    haveIntegrationSettingsChanged:
      CurrentLayerGetters.haveIntegrationSettingsChanged,

    // Shared Code Tab
    campaignCustomCodeLinesChanged:
      CurrentLayerGetters.campaignCustomCodeLinesChanged,

    // Traffic Allocation Tab
    hasTrafficAllocationToPublish:
      CurrentLayerGetters.hasTrafficAllocationToPublish,
    hasDraftHoldbackSetting: CurrentLayerGetters.hasDraftHoldbackSetting,
    customCodeTooltipText: CurrentLayerGetters.customCodeTooltipText,

    hasLayerMetrics: CurrentLayerGetters.hasLayerMetrics,
    areMetricsRequired: CurrentLayerGetters.areMetricsRequired,
    canUseUrlTargeting: PermissionsGetters.canUseUrlTargeting,
    hasDirtySettingsToPublish: CurrentLayerGetters.hasDirtySettingsToPublish,
  })
  class NavSidebarWeb extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        navGroup: getInitialState(props),
        isExpCollabEnabled: false,
      };
    }

    static componentId = `${componentId}-sidebar`;

    static propTypes = {
      activeTab: PropTypes.oneOf(Object.keys(CampaignManagerEnums.tabs))
        .isRequired,

      // Audiences Tab
      addedAudiences: PropTypes.instanceOf(Immutable.List),

      // Pages Tab
      addedViews: PropTypes.instanceOf(Immutable.List),

      areMetricsRequired: PropTypes.bool,
      // Shared Code Tab
      campaignCustomCodeLinesChanged: PropTypes.number,
      canUseUrlTargeting: PropTypes.bool,
      currentCampaignLiveChanges: PropTypes.instanceOf(Immutable.List),
      customCodeTooltipText: PropTypes.string,
      // Variations Tab
      draftChangesFromAllExperimentsOrSections: PropTypes.instanceOf(
        Immutable.List,
      ),
      draftTooltipText: PropTypes.string.isRequired,
      experiencesHaveUnpublishedChanges: PropTypes.bool,
      hasChangedAudienceConditions: PropTypes.bool,
      hasDirtySettingsToPublish: PropTypes.bool,
      // Traffic Allocation Tab
      hasDraftHoldbackSetting: PropTypes.bool,
      hasLayerMetrics: PropTypes.number,
      hasTrafficAllocationToPublish: PropTypes.bool,
      // Integrations Tab
      haveIntegrationSettingsChanged: PropTypes.bool,
      /* A list of optional sidebar rows to render. Available keys are:
       * {
       *   overview,
       *   experiences,
       *   variations,
       *   pages,
       *   audiences,
       *   integrations,
       *   metrics,
       *   custom_code,
       *   traffic_allocation,
       *   schedule,
       *   api_names,
       *   history,
       *   settings
       * }
       */
      items: PropTypes.object.isRequired,
      /* The name to display for this sidebar. */
      name: PropTypes.string.isRequired,
      removedAudiences: PropTypes.instanceOf(Immutable.List),
      removedViews: PropTypes.instanceOf(Immutable.List),
      /* Whether to show the plain CHANGED badge instead of draft/live counts for Variations (default false). */
      showVariationChangedBadge: PropTypes.bool,
    };

    static defaultProps = {
      showVariationChangedBadge: false,
      experiencesHaveUnpublishedChanges: false,
    };

    renderVariationChangeCounts() {
      const {
        draftChangesFromAllExperimentsOrSections,
        currentCampaignLiveChanges,
        draftTooltipText,
      } = this.props;

      return (
        <ul className="lego-badge">
          <li
            className={
              draftChangesFromAllExperimentsOrSections.size && 'badge__draft'
            }
            data-test-section={`${componentId}-variations-draft-line-count`}>
            <Poptip content={draftTooltipText}>
              <p>{draftChangesFromAllExperimentsOrSections.size}</p>
            </Poptip>
          </li>

          <li
            className={currentCampaignLiveChanges.size && 'badge__live'}
            data-test-section={`${componentId}-variations-live-line-count`}>
            <Poptip content={`${currentCampaignLiveChanges.size} Live`}>
              <p>{currentCampaignLiveChanges.size}</p>
            </Poptip>
          </li>
        </ul>
      );
    }

    renderVariationChangedBadge() {
      return (
        <ul className="lego-badge">
          {Boolean(
            this.props.draftChangesFromAllExperimentsOrSections.size,
          ) && (
            <li
              className="badge__draft"
              data-test-section={`${componentId}-variations-dirty-status`}>
              Changed
            </li>
          )}
        </ul>
      );
    }

    onToggle(groupKey) {
      const { navGroup } = this.state;
      const updated = { ...navGroup };
      updated[groupKey] = {
        isActive: !navGroup[groupKey].isActive,
      };

      const sessionKey = groupNavSessionKey(this.props);
      sessionStorage.setItem(sessionKey, JSON.stringify(updated));

      this.setState({
        navGroup: updated,
      });
    }

    renderNavListItemLabel(label, iconName) {
      const isPersonalizationLayer = LayerFns.isPersonalizationLayer(
        this.props.currentLayer,
      );

      return (
        <div>
          {isComponentGroupingEnabled() && !isPersonalizationLayer && (
            <Icon
              name={iconName}
              size="small"
              className="grouped-nav-icon lego-icon cursor--pointer soft--right"
            />
          )}
          {label}
        </div>
      );
    }

    componentDidMount() {
      const { currentProjectId } = this.props;
      HypothesisLinkingFns.isExpCollabIntegrationEnabled(currentProjectId)
        .then(isExpCollabEnabled => {
          this.setState({
            isExpCollabEnabled,
          });
        })
        .catch(() => {
          this.setState({
            isExpCollabEnabled: false,
          });
        });
    }

    render() {
      const {
        activeTab,
        canUseUrlTargeting,
        items,
        name,

        /* ===========================================================
         * PUBLISH STATUS INDICATORS
         * ===========================================================
         */
        // Variations Tab
        showVariationChangedBadge,
        // Pages Tab
        addedViews,
        removedViews,
        // Audiences Tab
        addedAudiences,
        removedAudiences,
        hasReorderedAudiences,
        hasChangedAudienceConditions,
        // Integratiosn Tab
        haveIntegrationSettingsChanged,
        // Shared Code Tab
        campaignCustomCodeLinesChanged,
        // Has draft holdback setting
        hasTrafficAllocationToPublish,
        hasDraftHoldbackSetting,
        customCodeTooltipText,
        hasLayerMetrics,
        areMetricsRequired,
        experiencesHaveUnpublishedChanges,
        hasDirtySettingsToPublish,
        currentLayerId,
        currentLayer,
        currentProjectId,
      } = this.props;

      const { navGroup, isExpCollabEnabled } = this.state;
      const isPersonalizationLayer = LayerFns.isPersonalizationLayer(
        currentLayer,
      );

      const summary = items.summary && isExperimentOverviewEnabled() && (
        <Track eventName="Clicks on Summary Page" eventType="click">
          <SidebarNavListItem
            isActive={activeTab === items.summary.name}
            testSection={`${componentId}-nav-item-overview`}
            href={items.summary.url}>
            <div className="flex flex-align--center">
              {this.renderNavListItemLabel('Summary', 'list-ul')}
            </div>
          </SidebarNavListItem>
        </Track>
      );

      const experiences = items.experiences && (
        <SidebarNavListItem
          isActive={activeTab === items.experiences.name}
          testSection={`${componentId}-nav-item-experiences`}
          href={items.experiences.url}>
          <div className="flex flex-align--center">
            <div>Experiences</div>
            {experiencesHaveUnpublishedChanges && (
              <div className="anchor--right flex--none soft--left">
                <ul className="lego-badge">
                  <li className="badge__draft">Unpublished</li>
                </ul>
              </div>
            )}
          </div>
        </SidebarNavListItem>
      );

      const variations = items.variations && (
        <SidebarNavListItem
          isActive={activeTab === items.variations.name}
          testSection={`${componentId}-nav-item-variations`}
          href={items.variations.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Variations', 'sliders')}
            <div className="anchor--right flex--none soft--left">
              {showVariationChangedBadge
                ? this.renderVariationChangedBadge()
                : this.renderVariationChangeCounts()}
            </div>
          </div>
        </SidebarNavListItem>
      );

      const pages = items.pages && (
        <SidebarNavListItem
          isActive={activeTab === items.pages.name}
          testSection={`${componentId}-nav-item-pages`}
          href={items.pages.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Activation', 'table-layout')}
            <div className="anchor--right flex--none soft--left">
              <ul className="lego-badge">
                {Boolean(addedViews.size || removedViews.size) && (
                  <li
                    className="badge__draft"
                    data-test-section={`${componentId}-pages-dirty-status`}>
                    Changed
                  </li>
                )}
              </ul>
            </div>
          </div>
        </SidebarNavListItem>
      );

      const audiences = items.audiences && (
        <SidebarNavListItem
          isActive={activeTab === items.audiences.name}
          testSection={`${componentId}-nav-item-audiences`}
          href={items.audiences.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Audiences', 'user-group')}
            <div className="anchor--right flex--none soft--left">
              <ul className="lego-badge">
                {Boolean(
                  addedAudiences.size ||
                    removedAudiences.size ||
                    hasChangedAudienceConditions,
                ) && (
                  <li
                    className="badge__draft"
                    data-test-section={`${componentId}-audiences-dirty-status`}>
                    Changed
                  </li>
                )}
              </ul>
            </div>
          </div>
        </SidebarNavListItem>
      );

      const integrations = items.integrations && (
        <SidebarNavListItem
          isActive={activeTab === items.integrations.name}
          testSection={`${componentId}-nav-item-integrations`}
          href={items.integrations.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Integrations', 'grid-2-plus')}
            <div className="anchor--right flex--none soft--left">
              <ul className="lego-badge">
                {haveIntegrationSettingsChanged && (
                  <li className="badge__draft">Changed</li>
                )}
              </ul>
            </div>
          </div>
        </SidebarNavListItem>
      );

      const metrics = items.metrics && (
        <SidebarNavListItem
          isActive={activeTab === items.metrics.name}
          testSection={`${componentId}-nav-item-metrics`}
          href={items.metrics.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Metrics', 'chart-line')}
            {areMetricsRequired && !hasLayerMetrics && (
              <div className="anchor--right flex--none soft--left">
                <Poptip content={metricsPoptip}>
                  <Icon
                    name="circle-exclamation"
                    size="small"
                    className="lego-icon color--brand vertical-align--middle cursor--pointer push-half--left flex--none"
                  />
                </Poptip>
              </div>
            )}
          </div>
        </SidebarNavListItem>
      );

      const custom_code = items.custom_code && (
        <SidebarNavListItem
          isActive={activeTab === items.custom_code.name}
          testSection={`${componentId}-nav-item-custom-code`}
          href={items.custom_code.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Shared Code', 'code')}
            <div className="anchor--right flex--none soft--left">
              <ul className="lego-badge">
                {Boolean(campaignCustomCodeLinesChanged) && (
                  <li
                    className="badge__draft"
                    data-test-section={`${componentId}-custom-code-draft-line-count`}>
                    {/* dangerouslySetInnerHTML is necessary to show the <br> breaks in the tooltip text */}
                    <Poptip
                      content={React.createElement('div', {
                        dangerouslySetInnerHTML: {
                          __html: customCodeTooltipText,
                        },
                      })}>
                      <p>{campaignCustomCodeLinesChanged}</p>
                    </Poptip>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </SidebarNavListItem>
      );

      const traffic_allocation = items.traffic_allocation && (
        <SidebarNavListItem
          isActive={activeTab === items.traffic_allocation.name}
          testSection={`${componentId}-nav-item-traffic-allocation`}
          href={items.traffic_allocation.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Traffic Allocation', 'split')}
            <div className="anchor--right flex--none soft--left">
              <ul className="lego-badge">
                {(hasTrafficAllocationToPublish || hasDraftHoldbackSetting) && (
                  <li
                    className="badge__draft"
                    data-test-section={`${componentId}-nav-item-changed-badge`}>
                    Changed
                  </li>
                )}
              </ul>
            </div>
          </div>
        </SidebarNavListItem>
      );

      const schedule = items.schedule && (
        <SidebarNavListItem
          isActive={activeTab === items.schedule.name}
          testSection={`${componentId}-nav-item-schedule`}
          href={items.schedule.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('Schedule', 'calendar')}
          </div>
        </SidebarNavListItem>
      );

      const hypothesis = items.hypothesis &&
        isHypothesisLinkingEnabled() &&
        isExpCollabEnabled && (
          <SidebarNavListItem
            isActive={activeTab === items.hypothesis.name}
            testSection={`${componentId}-nav-item-hypothesis`}
            href={items.hypothesis.url}>
            <div className="flex flex-align--center">
              {this.renderNavListItemLabel('Hypothesis', 'flask')}
            </div>
          </SidebarNavListItem>
        );

      const api_names = items.api_names && (
        <SidebarNavListItem
          isActive={activeTab === items.api_names.name}
          testSection={`${componentId}-nav-item-api-names`}
          href={items.api_names.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('API Names', 'brackets-curly')}
          </div>
        </SidebarNavListItem>
      );

      const history = items.history && (
        <SidebarNavListItem
          isActive={activeTab === items.history.name}
          testSection={`${componentId}-nav-item-history`}
          href={items.history.url}>
          <div className="flex flex-align--center">
            {this.renderNavListItemLabel('History', 'clock-rotate-left')}
          </div>
        </SidebarNavListItem>
      );

      const settings = items.settings && isPersonalizationLayer && (
        <SidebarNavListItem
          isActive={activeTab === items.settings.name}
          testSection={`${componentId}-nav-item-settings`}
          href={items.settings.url}>
          <div className="flex flex-align--center">
            <div>Settings</div>
            <div className="anchor--right flex--none soft--left">
              <ul className="lego-badge">
                {hasDirtySettingsToPublish && (
                  <li
                    className="badge__draft"
                    data-test-section={`${componentId}-settings-dirty-status`}>
                    Changed
                  </li>
                )}
              </ul>
            </div>
          </div>
        </SidebarNavListItem>
      );

      return (
        <Sidebar testSection={`${componentId}-sidebar`}>
          <Header campaignType={campaignType} layerId={currentLayerId} />
          {isComponentGroupingEnabled() && !isPersonalizationLayer ? (
            <SidebarNavListBody>
              <GroupedSidebarNavList
                label={NavGroupKey.Target}
                testSection={`${componentId}-grouped-nav-list`}
                onToggle={key => this.onToggle(key)}
                isActive={navGroup[NavGroupKey.Target].isActive}>
                {pages}
                {audiences}
              </GroupedSidebarNavList>
              <GroupedSidebarNavList
                label={NavGroupKey.Design}
                testSection={`${componentId}-grouped-nav-list`}
                onToggle={key => this.onToggle(key)}
                isActive={navGroup[NavGroupKey.Design].isActive}>
                {variations}
                {custom_code}
                {traffic_allocation}
              </GroupedSidebarNavList>
              <GroupedSidebarNavList
                label={NavGroupKey.Track}
                testSection={`${componentId}-grouped-nav-list`}
                onToggle={key => this.onToggle(key)}
                isActive={navGroup[NavGroupKey.Track].isActive}>
                {metrics}
                {integrations}
              </GroupedSidebarNavList>
              <GroupedSidebarNavList
                label={NavGroupKey.Plan}
                testSection={`${componentId}-grouped-nav-list`}
                onToggle={key => this.onToggle(key)}
                isActive={navGroup[NavGroupKey.Plan].isActive}>
                {schedule}
                {summary}
                {hypothesis}
              </GroupedSidebarNavList>
              <GroupedSidebarNavList
                label={NavGroupKey.Settings}
                testSection={`${componentId}-grouped-nav-list`}
                onToggle={key => this.onToggle(key)}
                isActive={navGroup[NavGroupKey.Settings].isActive}>
                {api_names}
                {history}
              </GroupedSidebarNavList>
            </SidebarNavListBody>
          ) : (
            <SidebarNavListBody>
              <SidebarNavList
                label={`Manage ${name}`}
                testSection={`${componentId}-nav-list`}>
                {summary}
                {experiences}
                {variations}
                {pages}
                {audiences}
                {integrations}
                {metrics}
                {custom_code}
                {traffic_allocation}
                {schedule}
                {api_names}
                {history}
                {settings}
              </SidebarNavList>
            </SidebarNavListBody>
          )}
        </Sidebar>
      );
    }
  }
  return NavSidebarWeb;
}

import config from 'atomic-config';

import ui from 'core/ui';

import { actions as ProjectIntegrationActions } from 'optly/modules/entity/project_integration';

const ICE_BASE_URL = `${config.get('env.ICE_BASE_URL')}/api`;

export function getLinkedHypothesis(experimentId, projectId) {
  const iceUrl = `${ICE_BASE_URL}/experimentation-project/${projectId}/experiment/${experimentId}/linked-cmp-hypothesis`;
  return fetch(iceUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 404) {
        return null;
      }
      throw new Error('Failed to fetch linked hypothesis');
    })
    .catch(err => {
      throw err;
    });
}

export function linkHypothesis(
  hypothesis,
  experimentId,
  projectId,
  experimentType,
) {
  if (!hypothesis) {
    return;
  }
  const iceUrl = `${ICE_BASE_URL}/experimentation-project/${projectId}/exp-experiment-cmp-hypothesis-links/cmp-hypothesis/${hypothesis.id}`;
  fetch(iceUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      exp_project_id: projectId,
      exp_experiment_id: experimentId,
      exp_experiment_type: experimentType,
      cmp_hypothesis_reference: `HPT-${hypothesis.hpt_reference}`,
    }),
  })
    .then(response => {
      if (response.ok) {
        ui.showNotification({
          type: 'success',
          message: 'The hypothesis has been linked to the experiment.',
        });
        return response.json();
      }
    })
    .catch(() => {
      ui.showNotification({
        type: 'error',
        message:
          'There was an error linking the hypothesis to the experiment. The experiment has been created successfully.',
      });
    });
}

export async function createAndLinkHypothesis(
  hypothesisToCreate,
  experimentId,
  projectId,
  experimentType,
) {
  const iceUrl = `${ICE_BASE_URL}/experimentation-project/${projectId}/create-and-link-cmp-hypothesis`;
  const response = await fetch(iceUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: hypothesisToCreate.name,
      planned_start_date: hypothesisToCreate.plannedStartDate,
      planned_end_date: hypothesisToCreate.plannedEndDate,
      exp_project_id: projectId,
      exp_experiment_id: experimentId,
      exp_experiment_type: experimentType,
    }),
  }).catch(() => {
    ui.showNotification({
      type: 'error',
      message:
        'There was an error creating and linking the hypothesis to the experiment. The experiment has been created successfully.',
    });
  });

  if (response.ok) {
    ui.showNotification({
      type: 'success',
      message: 'The hypothesis has been created and linked to the experiment.',
    });

    return response.json();
  }
}

export async function unlinkHypothesis(projectId, experimentId) {
  const iceUrl = `${ICE_BASE_URL}/experimentation-project/${projectId}/experiment/${experimentId}/unlink-cmp-hypothesis`;
  const response = await fetch(iceUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch(() => {
    ui.showNotification({
      type: 'error',
      message:
        'There was an error unlinking the hypothesis from the experiment.',
    });
  });

  if (response?.ok) {
    ui.showNotification({
      type: 'success',
      message: 'The hypothesis has been unlinked from the experiment.',
    });
    return response.json();
  }
}

export async function isExpCollabIntegrationEnabled(projectId) {
  const integrations = await ProjectIntegrationActions.fetchAll(
    { project_id: projectId },
    { force: true },
  );

  return (
    integrations.find(
      item => item.integration_id === 'experiment_collaboration',
    )?.enabled || false
  );
}

export default {
  linkHypothesis,
  createAndLinkHypothesis,
  getLinkedHypothesis,
  unlinkHypothesis,
  isExpCollabIntegrationEnabled,
};
